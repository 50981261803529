<template>
	<div class="a-timeline-insurance">
		<div class='a-timeline-item'>
			<div class='a-timeline-insurance-wrap'>
				<p class='a-timeline-insurance-title'>{{title}}</p>
				<div class='a-timeline-insurance-item' v-for='(el, i) in item.insurances' :key='i'>
					<div class='a-timeline-insurance-item-col'>
						<p class='a-timeline-insurance-item-title'>{{el.name}}
							<template v-if='Number(el.price.total.amount) > 0'>
								<span class='dot'>•</span> <strong>{{$helpers.amount(el.price.total.amount, el.price.total.currency)}}</strong>
							</template>
						</p>
						<p class='a-timeline-insurance-item-title'>{{$t("account.itinerary.insurance.program_name")}}
								<span class='dot'>•</span> <strong>A</strong>
						</p>
					</div>
					<!-- <div class='a-timeline-insurance-item-col' v-if='el.text'>
						<p class='a-timeline-insurance-item-text'>{{el.text}}</p>
					</div> -->
					<div class='a-timeline-insurance-item-col' v-if='el.logo'>
						<img class='a-timeline-insurance-item-logo' :src="`https://api.easytour.club${el.logo}`" />
					</div>
				</div>
				<a href='https://api.easytour.club/pdf/erv.pdf' target="_blank" class='app-link green-persian--text a-edit-insurance-dialog-link'>{{$t("account.itinerary.detailed_information")}}</a>
				<a href='https://api.easytour.club/pdf/erv_general_conditions_ru.pdf' target="_blank" class='app-link green-persian--text a-edit-insurance-dialog-link'>{{$t("account.itinerary.general_insurance_conditions")}}</a>
			</div>
		</div>

		<a-timeline-markup
			:item='item'
			:price='item.price'
			:title='title'
			:titleIcon='icon'
			:result='result'
			@update-order='$emit("update-order", $event)'
		/>
	</div>
</template>

<script>
	import ATimelineMarkup from '@/views/account/itinerary/markup';

	export default {
		props: ['item', 'title', 'icon', 'result'],
		components: {
			ATimelineMarkup
		}
	}
</script>
