<template>
	<div class='a-offer-extras-box' id='special-request'>
		<div class="row align-items-center">
			<div class="col">
				<p class='a-offer-extras-title'>{{$t('account.special_request.title')}}</p>
			</div>
			<div class="col-auto">
				<v-button outline small color='green' class='a-offer-extras-button' @click='add_request = false, textarea = ""' v-if='add_request'>
					<span class='mobile-hide'>{{$t('account.special_request.delete_request')}}</span>
					<span class='mobile-show'>{{$t('account.buttons.remove')}}</span>
				</v-button>
				<v-button outline small color='green' class='a-offer-extras-button' @click='add_request = true' v-else>
					<span class='mobile-hide'>{{$t('account.special_request.add_request')}}</span>
					<span class='mobile-show'>{{$t('account.buttons.add')}}</span>
				</v-button>
			</div>
		</div>
		<div class='a-offer-extras-special-request' v-if='add_request'>
			<v-alert
				color='orange'
				text
			>
				<div>
					<p class='v-alert-text'>{{$t('account.special_request.alert')}}</p>
				</div>
			</v-alert>
			<div class='a-offer-extras-special-request-row'>
				<div class='a-offer-extras-special-request-textarea'>
					<v-text-field
						:placeholder="$t('account.special_request.add_a_request_for_this_flight')"
						:class='textarea.length ? "valid" : ""'
						name='textarea'
						type='textarea'
						:rows='5'
						hide-details
						v-model='textarea'
						@input="$emit('input', $event)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			add_request: false,
			textarea: ''
		}),
		props: ['userComment', 'openRequest'],
		created() {
			if(this.userComment && this.userComment.length) {
				this.textarea = this.userComment ?? '';
				this.add_request = true;
			}
		},
		watch: {
			add_request() {
				this.$emit("open-request-status", false);
			},
			openRequest() {
				if(this.openRequest) {
					this.add_request = true
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-offer-extras {
		&-title {
			font-size: 18px;
			font-weight: 700;
			margin: 10px 0;
			@include minw( $grid-breakpoints-md ) {
				font-size: 22px * .9;
				margin: 15px 0;
			}
			@include minw( $grid-breakpoints-lg ) {
				font-size: 22px;
			}
		}
		&-special-request {
			margin-top: 20px;
			&-row {
				display: flex;
				align-items: stretch;
				flex-direction: column-reverse;
				margin-bottom: 20px;
				@include minw( $grid-breakpoints-xs ) {
					flex-direction: row;
				}
			}
			&-alert {
				margin-right: 5px;
				margin-bottom: 6px;
				display: flex;
				.v-alert {
					margin-bottom: 0;
				}
				@include minw( $grid-breakpoints-xs ) {
					flex: 1 0 281px;
				}
			}
			&-textarea {
				width: 100%;
				@include minw( $grid-breakpoints-xs ) {
					flex: 1 1 100%;
				}
			}
		}
		&-button {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				height: 40px;
				padding: 0 30px;
			}
		}
	}
</style>
