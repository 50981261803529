<template>
	<div class='a-timeline'>
		<div class="a-timeline-dotted">
			<div class="line"></div>
		</div>

		<template v-if='Object.keys(items)'>
            <template v-for="index in Object.keys(items).sort()">
                <div v-for='(item, i) in items[index]' :key="`${index}-${i}`">
                    <!-- Flight or Charter flight container -->
                    <a-timeline-container
                        :date='$moment(item.customDateFrom, "YYYY-MM-DD").format("dddd, MMMM DD")'
                        :title='`${$t("account.itinerary.timeline.flight_to")} ${item.customItinerary.DestinationLocName}`'
                        titleIcon='navigation/flights'
                        @open-dialog='deleteItem(item.id)'
                        @open-edit='$emit("open-edit", item)'
                        v-if='item.type === "flight" || item.type === "charterFlight"'
                        :result='result'
                    >
                        <p class='a-text-with-icon error--text' style='margin-bottom: 15px;' v-if='!item.isAvailable'>
                            <v-icon icon='refund' size='13' />
                            <span>{{$t("account.itinerary.info.no_longer_available")}}</span>
                        </p>
                        <a-timeline-flights
                            :item='item'
                            :itemOrigin='item.origin'
                            :itinerary='item.customItinerary'
                            :title='`${$t("account.itinerary.timeline.flight_to")} ${item.customItinerary.DestinationLocName}`'
                            titleIcon='navigation/flights'
                            :result='result'
                            @update-order='$emit("update-order", $event)'
                        />
                    </a-timeline-container>

                    <!-- Hotel container -->
                    <a-timeline-container
                        :date='$moment(item.customDateFrom, "YYYY-MM-DD").format("dddd, MMMM DD")'
                        :title='getTitleHotel(item)'
                        titleIcon='navigation/hotels'
                        @open-dialog='deleteItem(item.id)'
                        @open-edit='$emit("open-edit", item)'
                        v-if='item.type === "hotel"'
                        :result='result'
                    >
                        <a-timeline-hotels
                            :item='item'
                            :title='getTitleHotel(item)'
                            titleIcon='navigation/hotels'
                            :result='result'
                            :hideImage='hideImage'
                            @update-order='$emit("update-order", $event)'
                        />
                    </a-timeline-container>

                    <!-- Transfer container -->
                    <a-timeline-container
                        :date='$moment(item.customDateFrom, "YYYY-MM-DD").format("dddd, MMMM DD")'
                        :title='`${$t("account.itinerary.timeline.transfer_to")} ${item.customContract.destinationLocationName}`'
                        titleIcon='navigation/transfers'
                        @open-dialog='deleteItem(item.id)'
                        @open-edit='$emit("open-edit", item)'
                        v-if='item.type === "transfer"'
                        :result='result'
                    >
                        <a-timeline-transfers
                            :item='item'
                            :contract='item.customContract'
                            :title='`${$t("account.itinerary.timeline.transfer_to")} ${item.customContract.destinationLocationName}`'
                            titleIcon='navigation/transfers'
                            :result='result'
                            @update-order='$emit("update-order", $event)'
                        />
                    </a-timeline-container>

                    <!-- Car container -->
                    <!-- <a-timeline-container
                        :title='`${$t("routes.cars")} ${$t("account.on")} 30 днів`'
                        titleIcon='navigation/cars'
                        @open-dialog='deleteItem(item.id)'
                        @open-edit='$emit("open-edit", {type: "cars", searchData: null})'
                        :result='result'
                    >
                        <a-timeline-cars
                            :result='result'
                            :hideImage='hideImage'
                        />
                    </a-timeline-container> -->
                </div>
            </template>
		</template>

		<div v-for='(item, i) in insurance' :key='`${i}-i`'>
			<!-- Insurance container -->
			<a-timeline-container
				:title='$t("account.itinerary.insurance.insurance")'
				titleIcon='navigation/insurance'
				@open-dialog='deleteItem(item.id)'
				@open-edit='$emit("open-edit", item)'
				v-if='item.type === "insurance"'
				:result='result'
			>
				<a-timeline-insurance
					:item='item'
					:title='`${$t("account.itinerary.insurance.insurance")} ${$t("account.with")} ${$moment(item.dateFrom).format("DD/MM/YYYY")} ${item.dateTo ? `${$t("account.by")} ${$moment(item.dateTo).format("DD/MM/YYYY")}` : ""}`'
					icon='navigation/insurance'
					:result='result'
					@update-order='$emit("update-order", $event)'
				/>
			</a-timeline-container>
		</div>

		<div v-for='(item, i) in visa' :key='`${i}-v`'>
			<!-- Visa container -->
			<a-timeline-container
				:title='$t("account.itinerary.visa.visa")'
				titleIcon='navigation/visa'
				@open-dialog='deleteItem(item.id)'
				@open-edit='$emit("open-edit", item)'
				v-if='item.type === "visa"'
				:result='result'
			>
				<a-timeline-visa
					:item='item'
					:title='$t("account.itinerary.visa.visa")'
					icon='navigation/visa'
					:result='result'
					@update-order='$emit("update-order", $event)'
				/>
			</a-timeline-container>
		</div>

		<a-dialog-delete
			v-model='dialog'
			:title='$t("account.messages.are_you_sure_you_want_to_delete_this_trip")'
			@close-dialog="dialog = false"
			@action-dialog='deletedItemOrder'
			:loading='loading_btn'
		/>

		<a-dialog-time
			v-model='dialogTime'
			@close-dialog="dialogTime = false"
		/>
	</div>
</template>

<script>
	import ATimelineContainer from '@/views/account/itinerary/timeline/container';
	import ATimelineFlights from '@/views/account/itinerary/timeline/flights';
	import ATimelineHotels from '@/views/account/itinerary/timeline/hotels';
	import ATimelineTransfers from '@/views/account/itinerary/timeline/transfers';
	// import ATimelineCars from '@/views/account/itinerary/timeline/cars';
	import ATimelineInsurance from '@/views/account/itinerary/timeline/insurance';
	import ATimelineVisa from '@/views/account/itinerary/timeline/visa';
	import ADialogDelete from '@/components/account/dialogs/delete';
	import ADialogTime from '@/views/account/itinerary/dialogs/time';
	import { deletedItemOrder } from '@/services/request';
	import _ from 'lodash'

	export default {
		data: () => ({
			dialog: false,
			dialogTime: false,
			itemId: null,
			loading_btn: false
		}),
		props: {
			order: Object,
			result: {
				type: Boolean,
				default: false
			},
			hideImage: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			items() {
				return _.groupBy(_.flattenDeep(this.order.items.filter(item => item.type !== "insurances" && item.type !== "visa").map(item => {
					if(item.type === 'flight' || item.type === 'charterFlight') {
						return item.itineraries.map((el, i) => ({...item,
							customDateFrom: this.$moment(el.DepartureDT).format('YYYY-MM-DD H:m'),
							customItinerary: el,
							price: item.itineraries.length - 1 === i ? item.price : {
								basePrice: item.price.basePrice,
								total: item.price.total
							}
						}))
					} else if (item.type === 'transfer') {
						return item.contract.map((el, i) => ({...item,
								customDateFrom: this.$moment(`${el.dateFrom} ${el.time}`, "YYYY-MM-DD Hm").format('YYYY-MM-DD H:m'),
								customContract: el,
								price: item.contract.length - 1 === i ? item.price : {
									basePrice: item.price.basePrice,
									total: item.price.total
								}
						}))
					} else {
						return {
							...item,
							customDateFrom: item.dateFrom + " 23:59",
						}
					}
				})), 'customDateFrom')
			},
			insurance() {
				return this.order.items.filter(el => el.type === "insurance");
			},
			visa() {
				return this.order.items.filter(el => el.type === "visa");
			}
		},
		components: {
			ATimelineContainer,
			ATimelineFlights,
			ATimelineHotels,
			ATimelineTransfers,
			// ATimelineCars,
			ATimelineInsurance,
			ATimelineVisa,
			ADialogDelete,
			ADialogTime
		},
		methods: {
			getTitleHotel(item) {
				const night = this.$moment(item.searchData.dateTo).diff(this.$moment(item.searchData.dateFrom), "days");
				return `${night} ${this.$helpers.declOfNum(night, this.$t('account.declOfNum.nights'))}, ${item.countryName}`;
			},
			deleteItem(id) {
				this.itemId = id;
				this.dialog = true;
			},
			deletedItemOrder() {
				this.loading_btn = true;

				deletedItemOrder(this.order.id, this.itemId)
					.then(res => this.$emit('update-order', res.data), this.dialog = false)
					.finally(() => this.loading_btn = false)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline {
		&-dotted {
			@include minw( $grid-breakpoints-xs ) {
				position: absolute;
				top: 60px;
				bottom: 50px;
				width: 2px;
				left: 10px;
				.line {
					height: 100%;
					background-color: rgba($cyprus, .15);
					mask-image: url("../../../assets/line.svg");
					mask-repeat: repeat-y;
				}
				&:before,
				&:after {
					content: '';
					width: 8px;
					height: 8px;
					position: absolute;
					left: -3px;
					background-color: rgba($cyprus, .15);
					border-radius: 50%;
				}
				&:before {
					top: -10px;
				}
				&:after {
					bottom: -10px;
				}
			}
		}
	}
</style>
